import { Component } from '@angular/core';
import {DashboardComponent} from "../dashboard/dashboard.component";

@Component({
  selector: 'app-project-overview',
  standalone: true,
  imports: [
    DashboardComponent
  ],
  template: `
    <div>
      <app-dashboard/>
    </div>
  `,
})
export class ProjectOverviewComponent {

}
