
export const environment = {
    production: false,
    debugMode: false,
    baseUri: 'https://api.carvia-test.org/watchdog-service',
    // baseUri: 'http://localhost:8080',
    keycloak: {
        keycloakUrl: 'https://sso.carvia-test.org/',
        realm: 'watchdog',
        clientId: 'watchdog-web'
    },
    slack: {
      authEndpoint: 'https://slack.com/oauth/v2/authorize',
      clientId: '7678595164807.8149961497463',
      scope: 'chat:write,chat:write.public,incoming-webhook',
      redirectUri: 'https://watchdog.carvia-test.org/slack/redirect'
    },
    crashlytics:{
      baseUri: 'https://mtls-api.carvia-test.org/monitoring-service/protected',
    }
}
