import {Component, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';

import {MenuItem} from 'primeng/api';

import {AppMenu} from '../../layout/app.menu';
import {ProjectsResponse} from '../../models/project/projects-response';
import {ProjectService} from '../../services/projectService';
import {Breadcrumb} from 'primeng/breadcrumb';
import {filter} from 'rxjs';
import {NgClass, NgStyle} from '@angular/common';

@Component({
  selector: 'app-project-listing-menus',
  standalone: true,
  imports: [RouterOutlet, AppMenu, Breadcrumb, NgStyle, NgClass],
  template: `
    <div class="flex flex-col gap-0">

      <div class="card flex flex-col w-full" [ngStyle]="{'height': 'calc(100vh - 12rem)'}">
        <div class="flex-col border-bottom-1">
          <p-breadcrumb [model]="breadcrumbItems" [style]="{'padding': '0', 'padding-left': '1rem'}"></p-breadcrumb>
          <div class="flex justify-between items-center pt-3">
            <h4 class="pl-3">{{projectName}}</h4>
            <div class="flex gap-2 items-center">
              <button (click)="toggleStar()">
                <i class="pi text-xl" [ngClass]="isStarred ? 'pi-star-fill' : 'pi-star'" [style.color]="'orange'"></i>
              </button>

              <button>
                <i class="pi pi-ellipsis-v text-lg"></i>
              </button>
            </div>

          </div>
        </div>
        <div class="flex h-full">
          <div class="xl:w-18rem w-10rem h-full border-r border-surface-100 ">
            <div class="mr-5 h-full">
              <app-menu [model]="menuItems"></app-menu>
            </div>
          </div>
          <div class="flex-1 pl-3 pt-3">
            <router-outlet></router-outlet>
          </div>
        </div>

      </div>
    </div>
    `
})
export class ProjectListCardComponent implements OnInit {
  projectId: string = '';
  projectName: string = '';
  activeTab: string = '';
  menuItems: MenuItem[] = [];
  breadcrumbItems: MenuItem[] = [];
  isStarred = false;

  constructor(private route: ActivatedRoute, private projectService: ProjectService,private router: Router) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId') || '';
      if (this.projectId) {
        this.getProjectDetails();
      }

      this.updateActiveTab();

      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.updateActiveTab();
      });

      this.menuItems = [
        {
          items: [
            { label: 'Overview', icon: 'pi pi-home', routerLink: ['overview'], preserveFragment: true, routerLinkActiveOptions: { exact: false } },
            { label: 'Monitors', icon: 'pi pi-bullseye', routerLink: ['monitors'], preserveFragment: true, routerLinkActiveOptions: { exact: false } },
            { label: 'Incidents', icon: 'pi pi-exclamation-triangle', routerLink: ['incidents'], preserveFragment: true, routerLinkActiveOptions: { exact: false } },
            { label: 'Crashlytics', icon: 'pi pi-exclamation-circle', routerLink: ['crashlytics'], preserveFragment: true, routerLinkActiveOptions: { exact: false } },
          ]
        }
      ];
    });
  }

  getProjectDetails(): void {
    this.projectService.getProjectById(this.projectId).subscribe({
      next: (response: ProjectsResponse) => {
        this.projectName = response.name;
        this.updateBreadcrumb();
      },
      error: error => {
        console.error('Error fetching project:', error);
      }
    });
  }

  updateActiveTab(): void {
    this.activeTab = this.route.snapshot.firstChild?.url[0]?.path || '';
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    this.breadcrumbItems = [
      { label: 'Project List', routerLink: ['/projects'] },
      { label: this.projectName, routerLink: ['/projects', this.projectId] }
    ];

    if (this.activeTab) {
      this.breadcrumbItems.push({ label: this.capitalize(this.activeTab), routerLink: ['/projects', this.projectId, this.activeTab] });
    }
  }

  capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  toggleStar() {
    this.isStarred = !this.isStarred;
  }
}
