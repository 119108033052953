import {Component, inject, OnInit} from '@angular/core';
import {Button, ButtonDirective} from "primeng/button";
import {Card} from "primeng/card";
import {Menubar} from "primeng/menubar";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {NgClass, NgForOf} from '@angular/common';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {StatusService} from '../../services/status.service';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InputText} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelect, MultiSelectModule} from 'primeng/multiselect';
import {Toast} from 'primeng/toast';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {StatusRequest} from '../../models/status-request';
import {Monitor, StatusPageMonitorResponse} from '../../models/monitors/monitor-responses';
import {DragZoneComponent} from '../../components/drag-zone/drag-zone.component';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {MonitorService} from '../../services/monitor/monitor.service';
import {
  StatusPageMonitorConverterPipe
} from '../../pipes/type-converter-pipes/status-page/status-page-monitor-converter.pipe';
import {ProgressSpinner} from 'primeng/progressspinner';

@Component({
  selector: 'app-edit-monitor-status',
  standalone: true,
  imports: [
    Button,
    Card,
    Menubar,
    PrimeTemplate,
    TableModule,
    NgClass,
    FormsModule,
    InputText,
    ReactiveFormsModule,
    DropdownModule,
    MultiSelect,
    MultiSelectModule,
    Toast,
    ConfirmDialog,
    ButtonDirective,
    DragZoneComponent,
    CdkDrag,
    NgForOf,
    ProgressSpinner
  ],
  templateUrl: './edit-monitor-status.component.html',
  styleUrl: './edit-monitor-status.component.scss',
  providers: [StatusPageMonitorConverterPipe]
})
export class EditMonitorStatusComponent implements OnInit{
  sidebarVisible !: boolean;
  isLoading: boolean = false;
  private sidebarSubscription !: Subscription;
  private monitorService : MonitorService = inject(MonitorService);
  private fb: FormBuilder = inject(FormBuilder);
  private sidebarState: SidebarStateService = inject(SidebarStateService);
  private statusService: StatusService = inject(StatusService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  private messageService: MessageService = inject(MessageService);
  private router: Router = inject(Router);
  private statusPageMonitorConverter: StatusPageMonitorConverterPipe = inject(StatusPageMonitorConverterPipe)

  monitorsList:StatusPageMonitorResponse[]=[];
  statusPageMonitors:StatusPageMonitorResponse[]=[];
  selectedMonitors: StatusPageMonitorResponse[]=[];

  statusId: string | any;
  statusName: string | any;
  statusUrl: string | any;
  statusAccess: string | any;


  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.route.queryParams.subscribe(params => {
      this.statusId = params['statusId'];
      this.statusName = params['name'];
      this.statusAccess = params['access'];
      this.statusUrl = params['homePageUrl'];
    });
    this.getAllMonitors();
    this.getStatusById();
  }
  navigateAway(){
    this.confirmationService.confirm({
      message: '<div class="flex flex-column justify-content-center align-items-center"><span>Are you sure?</span><span>You want to cancel the operation</span></div>',
      icon: 'pi pi-info-circle',
      header: 'Confirmation',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.backToStatusPage();
      },
      reject: () => {},
      key: 'positionDialog'
    })
  }

  backToStatusPage() {
    this.router.navigate(['/status']);
  }



  onSubmit() {
    this.isLoading = true;
    if (this.selectedMonitors.length > 0) {
      const selectedMonitorIds = this.selectedMonitors.map((monitor: StatusPageMonitorResponse) => monitor.id);
      console.log('selectedMonitorIds', selectedMonitorIds);
      const payload = {
        name: this.statusName,
        homePageUrl: this.statusUrl,
        access: this.statusAccess,
        monitorIds: selectedMonitorIds,
        group: "Default Group",
      };

      this.statusService.addMonitors(this.statusId, payload).subscribe(
        (response) => {
          console.log('Status updated successfully:', response);
        },
        (error) => {
          console.error('Error updating status:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update status. Please try again'});
        },
        () => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Status Updated Successfully!'});
          setTimeout(() => {
            this.backToStatusPage();
          },1000)

        }
      );
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Form is invalid. Please select at least one monitor.'});

    }
  }

  onClearMonitors(): void {
    this.selectedMonitors = [];
  }

  getStatusById() {
    this.statusService.getStatusById(this.statusId).subscribe(
      (response: StatusRequest[]) => {
        console.log(response,'response')

      },
      (error) => {
        console.error('Error fetching monitor data:', error);
      }
    );
  }

  getAllMonitors() {
    this.getMonitorsByStatusId();
    this.monitorService.getAll('','').subscribe({
      next: (res) => {
        res.forEach(monitor => {
          this.monitorsList.push(this.statusPageMonitorConverter.transform(monitor));
        })
        this.monitorsList.reverse()
        console.log('allmonitors',this.monitorsList);
        const allMonitors = new Set(this.monitorsList.map((spm => spm.id)))
        const selected:StatusPageMonitorResponse[] = [];
        this.statusPageMonitors.map(spm => {
          if (allMonitors.has(spm.id)){
            selected.push({name: spm.name, id: spm.id, rank: -1, httpUrl: spm.httpUrl});
          }
        })
        this.selectedMonitors = selected;
        console.log('selectedMonitors', selected);
      },
      error: (err) => {
        console.error('Error getting monitors list:', err);
      }
    })
  }

  getMonitorsByStatusId() {
    this.statusService.getMonitorsByStatusId(this.statusId).subscribe({
      next: (res) => {
        this.statusPageMonitors = res;
      }
    })
  }

}
