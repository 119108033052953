import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class CrashlyticsService {

  constructor(private httpClient: HttpClient) { }

  getCrashlytics(id: number) : Observable<any>{
    return this.httpClient.get<any>(`${environment.crashlytics.baseUri}/deployment/${id}`);
  }
}
