import {CommonModule} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  Query,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {FloatLabelModule} from 'primeng/floatlabel';
import {MenubarModule} from 'primeng/menubar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {DataViewModule} from 'primeng/dataview';
import {MonitorCardComponent, ViewMode} from '../../components/monitor-card/monitor-card.component';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MonitorService} from '../../services/monitor/monitor.service';
import {Select, SelectModule} from 'primeng/select';
import {MonitorEventType, MonitorStatus, Type} from '../../models/enums';
import {MonitorEvent} from '../../models/events';
import {MonitorTypeConverterPipe} from '../../pipes/type-converter-pipes/monitor-type-to-monitor.pipe';
import { Monitor } from '../../models/monitors/monitor-responses';
import { MonitorType } from '../../models/monitors/monitor-requests';
import {Skeleton} from 'primeng/skeleton';
import {Card} from 'primeng/card';
import {ProjectRequest} from '../../models/project/project-request';
import {ProjectService} from '../../services/projectService';
import { SelectButton, SelectButtonChangeEvent } from 'primeng/selectbutton';
import { Table, TableModule, TableRowSelectEvent } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import {Tag} from 'primeng/tag';
import {ProjectsResponse} from '../../models/project/projects-response';

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-monitors',
  standalone: true,
  imports: [CommonModule, MenubarModule, ButtonModule, FloatLabelModule, FormsModule, DropdownModule, InputTextModule, DataViewModule, MonitorCardComponent, Select, MonitorTypeConverterPipe, Skeleton, Card, SelectButton, TableModule, ChipModule, TooltipModule, MultiSelect, Tag],
  templateUrl: './monitors.component.html',
  styleUrl: './monitors.component.scss'
})
export class MonitorsComponent implements OnInit {

  protected readonly MonitorStatus = MonitorStatus;
  sidebarVisible !: boolean;
  private sidebarSubscription !: Subscription;
  searchInputText: string = '';
  totalSkeletons : any = [];
  private sidebarState : SidebarStateService = inject(SidebarStateService);
  private router: Router = inject(Router);
  private monitorService: MonitorService = inject(MonitorService);
  private projectService: ProjectService = inject(ProjectService);
  private monitorTypeConverter: MonitorTypeConverterPipe = inject(MonitorTypeConverterPipe);
  private route: ActivatedRoute = inject(ActivatedRoute);
  loading = true;
  monitors: Monitor[] = [];
  filteredMonitors: Monitor[] = [];
  projects: ProjectRequest[] = []
  selectedProject: string | null = null;
  projectId: string = '';
  projectName: string = '';
  type: string = '';
  groups: any[] | undefined;
  selectedGroup: any;
  types: MonitorType[] = [
    {name: 'SSL', code: 'ssl'},
    {name: 'HTTPS', code: 'https'}
  ];
  selectedType!: MonitorType;
  @ViewChildren('MonitorCardComponent') monitorCardComponent!: QueryList<MonitorCardComponent>;
  showProjectMonitors: boolean = false;

  viewOptions: any[] = [
    { value: 'list', icon: 'pi pi-bars'},
    { value: 'table', icon: 'pi pi-table'},
  ]
  selectedViewOption: any = this.viewOptions[0].value;
  columns!: Column[]
  selectedColumns!: Column[]

  intializeCols(){
    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'project', header: 'Project' },
      { field: 'scheduleInterval', header: 'Check Freq' },
      { field: 'monitorStatusCheckedAt', header: 'Last Checked'}
    ]
    this.selectedColumns = this.columns;
    this.getViewOption();
  }

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.getProjects();
    this.intializeCols();
      this.route.parent?.paramMap.subscribe(params => {
        const id = params.get('projectId');
        this.projectId = id !== null && id !== undefined ? id : '';

        if (this.projectId) {
          this.showProjectMonitors = true;

      }
    });

    if(this.showProjectMonitors) {
      this.selectedProject = this.projects.find(p => p.id === this.projectId)?.name || '';
      this.getMonitors();
    }


    this.getMonitors();
    for (let i = 1; i < 12; i++) {
      this.totalSkeletons.push({id:i});
    }

  }


  navigateToCreateMonitor() {
    const navigationExtras = this.projectId
      ? { queryParams: { projectId: this.projectId } }
      : {};

    this.router.navigate(['/monitors/create'], navigationExtras);
  }

  handleDeleteEvent(monitor: Monitor) {
    this.monitors = [...this.monitors.filter(m => m.id !== monitor.id)];
  }
  handlePauseEvent(monitor: Monitor) {
    const index = this.monitors.indexOf(monitor);
    if (index != -1) {
      this.monitors = [...this.monitors.slice(0, index),
        monitor,
        ...this.monitors.slice(index + 1)
      ]
    }
  }
  updatedMonitorEvent(monitorEvent: MonitorEvent) {
    switch (monitorEvent.type) {
      case MonitorEventType.DELETE: {
        this.handleDeleteEvent(monitorEvent.monitor);
        break;
      }
      case MonitorEventType.PAUSE: {
        this.handlePauseEvent(monitorEvent.monitor);
        break;
      }
    }
  }

  onProjectSelect(project: any): void {
    this.selectedProject = project;

    this.projectId = project ? project.id : null;
    this.getMonitors();
  }
  onTypeSelect(type: any): void {
    this.selectedType = type;
    // this.projectId = project ? project.id : null;
    this.getMonitors();
  }

  getProjects(){
    this.projectService.getAll().subscribe({
      next: data => {
        this.projects = data;
      },
      error: error => {
        console.log(error);
      },
      complete: () => {
      }
    })
  }

  getMonitors() {
    this.loading = true;

    let monitorType = '';
    if (this.selectedType) {
      if (this.selectedType.code === 'https') {
        monitorType = 'HTTP_MONITOR';
      } else if (this.selectedType.code === 'ssl') {
        monitorType = 'SSL_MONITOR';
      }
    }

    this.monitorService.getAll(monitorType, this.projectId || '').subscribe({
      next: (data) => {
        this.monitors = data;
        this.filteredMonitors = data;
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching monitors:', err);
        this.loading = false;
      },
    });
  }

  getViewOption(){
    this.selectedViewOption = localStorage.getItem('viewOption') || 'list';
    const selectedCols = localStorage.getItem('selectedColumn');
    this.selectedColumns = []
    if(selectedCols !== null){
      this.selectedColumns =  JSON.parse(selectedCols);
    }
  }
  saveViewOption($event: any, eventType: string) {
    if(eventType === 'view'){
      localStorage.setItem('viewOption', $event.value);
    } else if (eventType ==='columns') {
      console.log($event)
      localStorage.setItem('selectedColumn', JSON.stringify($event.value))
    }
  }
  onRowSelect($event: TableRowSelectEvent) {
    const monitorId = $event.data.id;
    const httpUrl = $event.data.httpUrl;
    this.router.navigate(["monitors/monitors-detail"], {
      queryParams: {  monitorId, httpUrl, projectId: this.projectId }
    });
  }

  onSearchChange(): void {
    const searchText = this.searchInputText.toLowerCase();
    this.filteredMonitors = this.monitors.filter((monitor) =>
      monitor.name.toLowerCase().includes(searchText)
    );
  }

  protected readonly Array = Array;
}
