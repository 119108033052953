import { Component } from '@angular/core';
import {IncidentsComponent} from '../incidents/incidents.component';

@Component({
  selector: 'app-project-incidents',
  standalone: true,
  imports: [
    IncidentsComponent
  ],
  template: `
    <div>
      <app-incidents/>
    </div>
  `,

})
export class ProjectIncidentsComponent {

}
