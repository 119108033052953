import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-footer',
    template: `<div class="layout-footer">
        A Product by
        <a href="https://carvia.tech" target="_blank" rel="noopener noreferrer" class="text-primary font-bold hover:underline">Carvia</a>
    </div>`
})
export class AppFooter {}