
<div class="card flex flex-column overflow-x-auto" [ngStyle]="projectId ? {'padding':'0','padding-left':'1rem'} : {}">
  <p-menubar class="menu" styleClass="border-none pl-0 pr-0">
    <ng-template pTemplate="start">
      <span class="text-2xl">Incidents</span>
    </ng-template>
  </p-menubar>
  <p-menubar class="menu" styleClass="border-none pl-0 pr-0">
    <ng-template pTemplate="start">
      <div class="flex align-items-center gap-3">
        <input
          type="search"
          class="w-16rem"
          placeholder="Search"
          pInputText
          [(ngModel)]="searchInputText"
        />
        <span class="text-xl border-left-1 pl-4 ml-4 p-1">Quick Filter</span>


        @if (!isProjectSelected){
          <div class="flex justify-content-center ml-4">
            <p-select
              [options]="projects"
              [(ngModel)]="selectedProject"
              optionLabel="name"
              [showClear]="!!selectedProject"
              placeholder="Projects"
              (ngModelChange)="onProjectSelect($event)"
            ></p-select>

          </div>
        }
        <div class="flex justify-content-center ml-4">
          <p-select
            [options]="statusOptions"
            [(ngModel)]="selectedStatus"
            placeholder="Status"
            [showClear]="!!selectedStatus"
            (ngModelChange)="onStatusChange($event)"
          ></p-select>
        </div>

      </div>
    </ng-template>
  </p-menubar>
  <div class="mt-2">
    @if (loading) {
      <app-skeleton-table [loading]="loading" [headers]="incidentHeader"></app-skeleton-table>
    }
    @if (incidentsData.length>0) {
      <p-table
        [value]="incidentsData"
        stripedRows
        [paginator]="incidentsData.length >0"
        [rows]="page.size"
        [showCurrentPageReport]="incidentsData.length >0"
        [tableStyle]="{ 'min-width': '50rem' }"
        [lazy]="true"
        size="small"
        (onPage)="onPageChange($event)"
        [totalRecords]="page.totalElements"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[5,10,25,50]">
        <ng-template pTemplate="header">
          <tr>
            @for (header of incidentHeader; track header) {
              @if (!(projectId && header === 'Project Name')) {
                <th>{{header}}</th>
              }
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <p-tag
                [value]="item.status"
                [severity]="item.status === 'ONGOING' ? 'danger' : (item.status === 'RESOLVED' ? 'success' : 'info')">
              </p-tag>
            </td>
            <td class="text-500-16px">{{ item.monitor.name }}</td>
            <td class="text-500-16px" showDelay="1000" pTooltip="{{item.rootCause}}">{{ item.rootCause.substring(0,100) }}...</td>
            @if (!projectId) {
              <td class="text-500-16px">{{ item.project.name }}</td>
            }
            <td class="text-500-16px">{{ item.createdDate | date: 'dd-MM-yyyy HH:mm'  }}</td>
            <td class="text-500-16px">{{ item.incidentDuration | duration}}</td>
          </tr>
        </ng-template>
      </p-table>

    } @else if (incidentsData.length==0 && !loading){
      <div style="text-align: center; margin-top: 4%">
        <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_814_160)">
            <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_814_160">
              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg></div>
        <div class=" flex justify-content-center text-2xl my-2">
          No Data Found
        </div>
        <div class="info-2 flex justify-content-center text-xl my-2">
          There is no incidents. Everything seems to be working fine
        </div>
      </div>
    }
  </div>


</div>
