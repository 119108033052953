<!-- <div class="main-container">
  @if(displayNavSideBarSideMenu) {
    <app-navbar/>
    <app-sidebar/>

    @if(displaySideMenu) {
      <app-side-menu/>
    }
  } -->
  <router-outlet/>
<!-- </div> -->
