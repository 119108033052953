import {Component, OnInit, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {TableModule} from "primeng/table";
import {DatePipe, NgClass, NgStyle} from '@angular/common';
import {Subscription} from 'rxjs';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {IncidentsComponent} from '../incidents/incidents.component';
import {ProjectService} from '../../services/projectService';
import {ProjectIncidents, ProjectRequest} from '../../models/project/project-request';
import {Badge} from 'primeng/badge';
import {DashboardService} from '../../services/dashboardService';
import {Incident, MonitorStatusCount} from '../../models/monitors/monitor-responses';
import {ActivatedRoute, Router} from '@angular/router';
import {DurationPipe} from '../../pipes/duration-pipe/duration.pipe';
import {Tag} from 'primeng/tag';
import {Tooltip} from 'primeng/tooltip';
import {SkeletonTableComponent} from '../../components/common/skeleton-table/skeleton-table.component';
import {Menu} from 'primeng/menu';
import { StatsWidget } from "./widgets/statswidget";
import {ProjectsResponse} from '../../models/project/projects-response';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    FormsModule,
    TableModule,
    NgClass,
    Badge,
    DatePipe,
    DurationPipe,
    Tag,
    Tooltip,
    SkeletonTableComponent,
    Menu,
    StatsWidget,
    NgStyle
  ],
  // template: `
  //       <div class="grid grid-cols-12 gap-8">
  //           <app-stats-widget class="contents" />
  //           <!-- <div class="col-span-12 xl:col-span-6">
  //               <app-recent-sales-widget />
  //               <app-best-selling-widget />
  //           </div>
  //           <div class="col-span-12 xl:col-span-6">
  //               <app-revenue-stream-widget />
  //               <app-notifications-widget />
  //           </div> -->
  //       </div>
  //   `,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  projects: ProjectRequest[] = []
  monitorStatusData: MonitorStatusCount | null = null;
  projectIncidents: ProjectIncidents[]=[];
  upCount: number = 0;
  downCount: number = 0;
  pausedCount: number = 0;
  incidentsData:Incident[]=[]
  loading = true;
  incidentHeader: string[] = [];
  projectId: string | null | undefined = null;
  @ViewChild('incidentsComponent') incidentsComponent!: IncidentsComponent;
  value = [
    { id:1,label: 'Apps', color1: '#34d399', color2: '#fbbf24', value: 25, icon: 'pi pi-table' },
    { id:2,label: 'Messages', color1: '#fbbf24', color2: '#60a5fa', value: 15, icon: 'pi pi-inbox' },
    { id:3,label: 'Media', color1: '#60a5fa', color2: '#c084fc', value: 20, icon: 'pi pi-image' },
    { id:4,label: 'System', color1: '#c084fc', color2: '#c084fc', value: 10, icon: 'pi pi-cog' }
  ];
  constructor(private sidebarState: SidebarStateService,
              private projectService:ProjectService,
              private dashboardService:DashboardService,
              private router: Router,
              private route: ActivatedRoute,) {
  }

  ngOnInit(): void {
    this.incidentHeader = ['Project Name', 'Monitor Name', 'Root Cause','Started','Duration (HH:MM:SS)'];

    this.route.parent?.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');

      if (this.projectId) {
        this.fetchData();
      }
    });
    this.fetchData()
  }

  fetchData() {
    this.monitorStatusCount();
    this.getOngoingProjectIncident();
    this.getOngoingIncidents();
  }

  get badgeSize(): string {
    return this.projectId ? '5rem' : '7rem';
  }

  get fontSize(): string {
    return this.projectId ? '2.5rem' : '5rem';
  }

  monitorStatusCount(){
    const projectId = this.projectId ? Number(this.projectId) : undefined;

    this.dashboardService.getMonitorStatusCount(projectId).subscribe({
      next: data => {
        this.monitorStatusData = data;

        this.upCount = this.monitorStatusData?.upMonitorCount || 0;
        this.downCount = this.monitorStatusData?.downMonitorCount || 0;
        this.pausedCount = this.monitorStatusData?.pausedMonitorCount || 0;
      },
      error: error => {
        console.log(error);
      }
    });
  }

  getOngoingProjectIncident(){
    this.dashboardService.getOngoingProjectIncidents().subscribe({
      next: data => {
        this.projectIncidents = this.projectId ? data.filter(incident => incident.project.id.toString() === this.projectId) : data;

      },
      error: error => {
        console.log(error);
      },
      complete: () => {
      }
    })
  }

  getOngoingIncidents(){
    this.dashboardService.getOngoingIncidents().subscribe({
      next: (response) => {
        const { content, page } = response;
        this.incidentsData = this.projectId ? content.filter(incident => incident.id.toString() === this.projectId) : content;
        // this.page = page;
        this.loading = false;

      },
      error: error => {
        console.log(error);
      },
      complete: () => {
        this.loading = false;

      }
    })
  }


  navigateToIncidents(){
    this.router.navigate(['/incidents']);
  }

}
