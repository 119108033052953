import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Button} from 'primeng/button';
import {NgClass, NgOptimizedImage} from '@angular/common';
import {Ripple} from 'primeng/ripple';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import { DrawerModule } from 'primeng/drawer';
import {ProjectService} from '../../services/projectService';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    DrawerModule,
    Button,
    NgOptimizedImage,
    Ripple,
    RouterLink,
    RouterLinkActive,
    NgClass
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = [];
  isExpanded: boolean = false;
  sidebarVisible : boolean = false;
  private sidebarChangeSubscription!: Subscription;

  constructor(private sidebarState: SidebarStateService,private projectsService: ProjectService, private router: Router) {
  }

  onSidebarHide() {
    this.sidebarVisible = false;
    this.sidebarState.setSidebarVisible(false);
  }

  ngOnInit(): void {
    this.sidebarChangeSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.loadProjects();
  }

  loadProjects() {
    this.projectsService.getAll().subscribe({
      next: (projects) => {
        this.menuItems = projects.map(project => ({
          id: project.id ?? Math.random(),
          label: project.name,
          routerLink: [`/project-listing/${project.id}/overview`]
        }));
      },
      error: (err) => console.error('Error fetching projects:', err)
    });
  }

  toggleAccordion() {
    this.isExpanded = !this.isExpanded; // Toggle accordion state
  }


  navigateToProject(projectId: number) {
    this.router.navigate(['/project-listing', projectId, 'overview']);
  }


  // drawer = {
  //   handle: {
  //     background: '{indigo.50}'
  //   },
  //   colorScheme: {
  //     light: {
  //       root: {
  //         background: '{indigo.50}',
  //       },
  //       handle: {
  //         background: '{indigo.50}',
  //       }
  //     },
  //   }
  // }
}
