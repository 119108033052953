<p-toast key="monitorToast"></p-toast>
  <p-confirmDialog
    key="positionDialog"
    rejectButtonStyleClass="p-button-outlined" />
  @if (loading){
    <div class="loader-container">
      <p-progress-spinner ariaLabel="loading" />
    </div>
  }

@if (view === 'list') {
<div class="flex border-bluegray-300 shadow-none " >
  <p-card class="flex flex-row gap-2 w-full p-0">
    <div class="flex flex-row  align-items-center p-0 border-round-xl" >
      @switch (!monitor.paused){
        @case (true){
          <div class="align-self-center flex btn-mock">
            <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.82726 6.32991L0.602917 11.9336C0.544492 11.9733 0.476615 11.9961 0.406516 11.9995C0.336417 12.003 0.266721 11.9869 0.204851 11.9531C0.142982 11.9192 0.0912551 11.8688 0.0551809 11.8073C0.0191068 11.7458 3.62529e-05 11.6754 0 11.6037L0 0.39632C3.62529e-05 0.324595 0.0191068 0.254223 0.0551809 0.192699C0.0912551 0.131175 0.142982 0.0808037 0.204851 0.0469494C0.266721 0.0130951 0.336417 -0.00297422 0.406516 0.000453015C0.476615 0.00388025 0.544492 0.0266759 0.602917 0.0664116L8.82726 5.67009C8.8804 5.7063 8.92397 5.75536 8.9541 5.81291C8.98423 5.87046 9 5.93473 9 6C9 6.06527 8.98423 6.12954 8.9541 6.18709C8.92397 6.24464 8.8804 6.2937 8.82726 6.32991Z" fill="#4338CA"/>
            </svg>
          </div>
        }
        @case (false) {
          <div class="align-self-center flex btn-mock">
            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0H3.75V9H0V0ZM5.25 0H9V9H5.25V0Z" fill="#64748B"/>
            </svg>
          </div>
        }
      }

      <div class="flex-column ml-5 cursor-pointer w-full" (click)="goToMonitorDetailsPage(monitor.id,monitor.httpUrl)">
        <ul class="list-none flex flex-column gap-2 m-0 p-0">
          <li class=""><span class="text-500-20px" >{{monitor.name}}</span></li>
          <li class="flex flex-row gap-1">
            <p-chip label="{{monitor.group}}" icon="pi pi-sitemap" />
            <p-chip label="{{monitor.monitorType.toLowerCase().replace('_',' ')}}" icon="pi pi-sitemap" />
            <p-chip label="{{monitor.scheduleInterval.toLowerCase().replace('_', ' ')}}" icon="pi pi-stopwatch" />
            <p-chip label="{{monitor.project}}" />
          </li>
        </ul>
      </div>
      <div class="flex flex-row justify-content-center align-items-center">
        <div class="flex-column ml-auto gap-3">
          <div class="flex flex-row">
            <div class="flex-column mr-5 text-right">
              <ul class="list-none m-0 p-0">
                  @switch (monitor.status){
                    @case (MonitorStatus.UP){
                      <li class="mt-8px">
                        <div class="flex flex-row justify-content-end align-items-center">
                          <span class="mr-2 status-label" [ngClass]="{'text-green': monitor.active, 'text-bgray': !monitor.active}">Up</span>
                          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path [ngClass]="{'svg-bgray': !monitor.active}" d="M8.81793 0.230316L15.9159 11.1961C15.9663 11.274 15.9951 11.3645 15.9995 11.458C16.0038 11.5514 15.9835 11.6444 15.9406 11.7269C15.8977 11.8094 15.8339 11.8783 15.756 11.9264C15.678 11.9745 15.5889 12 15.498 12L1.30205 12C1.2112 12 1.12206 11.9745 1.04413 11.9264C0.966204 11.8783 0.9024 11.8094 0.859518 11.7269C0.816636 11.6444 0.796281 11.5514 0.800623 11.458C0.804964 11.3645 0.833838 11.274 0.88417 11.1961L7.98217 0.230316C8.02803 0.159467 8.09017 0.101376 8.16307 0.0611989C8.23597 0.0210215 8.31737 -3.28593e-07 8.40005 -3.32207e-07C8.48273 -3.35821e-07 8.56413 0.0210215 8.63703 0.0611988C8.70992 0.101376 8.77207 0.159467 8.81793 0.230316Z" fill="#15803D"/>
                          </svg>
                        </div>
                      </li>
                    }
                    @case (MonitorStatus.DOWN){
                      <li class="mt-8px">
                        <div class="flex flex-row justify-content-end align-items-center">
                          <span class="mr-2 status-label" [ngClass]="{'text-red': monitor.active, 'text-bgray': !monitor.active}">Down</span>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.4954 12.1402 12.1402 15.4954 8 15.5ZM7.25 10.25V11.75H8.75V10.25H7.25ZM7.25 4.25V8.75H8.75V4.25H7.25Z" fill="#EF4444" [ngClass]="{'svg-bgray':!monitor.active}"/>
                          </svg>
                        </div>
                      </li>
                    }
                  }
                <li class="mt-8px">
                    <div class="">
                      <span class="text-surface-900 dark:text-surface-0">Last checked: {{monitor.monitorStatusCheckedAt | date: 'dd-MMM-yyyy HH:mm:ss'}}</span>
                    </div>
                </li>
              </ul>
            </div>
            <div class="align-self-center">
              <div class="card flex justify-center relative">
                <i id="menuParent" class="pi pi-ellipsis-h cursor-pointer align-self-center relative" (click)="showMenu($event, menu, monitor)"></i>
                <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body" class="custom-menu"></p-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</div>
}@else if (view === 'table') {
    <td>
      <!-- Actions -->
      <div>
        <button outlined="true"  pButton class="border-none" icon="pi pi-ellipsis-h" (click)="showMenu($event, menu, monitor)"></button>
        <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body" class="custom-menu"></p-menu>
      </div>
    </td>
}
