import { Component } from '@angular/core';
import {MonitorsComponent} from '../monitors/monitors.component';

@Component({
  selector: 'app-project-monitors',
  standalone: true,
  imports: [
    MonitorsComponent
  ],
  template: `
    <div>
        <app-monitors></app-monitors>
    </div>
  `
})
export class ProjectMonitorsComponent {

}
