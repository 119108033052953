import {Component, inject, OnInit} from '@angular/core';
import {Menu} from "primeng/menu";
import {NgClass} from '@angular/common';
import {filter, Subscription} from 'rxjs';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ProjectService} from "../../services/projectService";

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [
    Menu,
    NgClass
  ],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent implements OnInit {

  sidebarVisible !: boolean;
  sidebarSubscription !: Subscription;
  menuItems: any[] = [];
  private sidebarState: SidebarStateService = inject(SidebarStateService);

  constructor(private router: Router, private route: ActivatedRoute, private projectsService: ProjectService) {}

  ngOnInit() {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateMenuItems();
      });

    this.updateMenuItems();
  }


  updateMenuItems() {
    const currentRoute = this.router.url;

    let projectId = '';

    // Extract the project ID from the URL
    const match = currentRoute.match(/project-listing\/(\d+)/);
    if (match) {
      projectId = match[1]; // Extracted project ID
    }

    if (currentRoute.includes('dashboard')) {
      this.menuItems = [
        { label: 'Overview', icon: 'pi pi-home', routerLink: ['/dashboard'] },
        { label: 'Reports', icon: 'pi pi-chart-bar', routerLink: ['/dashboard/reports'] },
        { label: 'Settings', icon: 'pi pi-cog', routerLink: ['/dashboard/settings'] }
      ];
    } else if (currentRoute.includes('incidents')) {
      this.menuItems = [
        { label: 'Active Incidents', icon: 'pi pi-exclamation-triangle', routerLink: ['/incidents/active'] },
        { label: 'Resolved Incidents', icon: 'pi pi-check', routerLink: ['/incidents/resolved'] }
      ];
    } else if (currentRoute.includes('projects')) {
      this.menuItems = [
        { label: 'Project List', icon: 'pi pi-briefcase', routerLink: ['/projects'] },
        { label: 'New Project', icon: 'pi pi-plus', routerLink: ['/projects/new'] }
      ];
    // } else if (currentRoute.includes('project-listing')) {
    //
    //   this.menuItems = [
    //     { label: 'Overview', icon: 'pi pi-briefcase', routerLink: ['/projects'] },
    //     { label: 'Monitors', icon: 'pi pi-bullseye', routerLink: ['/projects/new'] }
    //   ];
    } else if (currentRoute.includes('project-listing') && projectId) {
      this.menuItems = [
        { label: 'Overview', icon: 'pi pi-briefcase', routerLink: [`/project-listing/${projectId}/overview`] },
        { label: 'Monitors', icon: 'pi pi-bullseye', routerLink: [`/project-listing/${projectId}/monitors`] }
      ];
    } else {
      this.menuItems = [];
    }
  }

  loadProjects() {
    this.projectsService.getAll().subscribe({
      next: (projects) => {
        this.menuItems = projects.map(project => ({
          label: project.name,
          icon: 'pi pi-folder',
          routerLink: [`/projects/${project.id}`]
        }));
      },
      error: (err) => console.error('Error fetching projects:', err)
    });
  }


  menuStyle = {
    colorScheme: {
      light: {
        root: {
          list: {
            gap: '10px'
          }
        }
      }
    }
  }
}
