<div class="card flex flex-column gap-2 overflow-x-auto ">
  @if (projectId){
    <p-breadcrumb [model]="breadcrumbItems" [style]="{'padding':0,'padding-left':'1rem'}"></p-breadcrumb>
  }
  <p-menubar class="menu" styleClass="border-none pl-0 pr-0">
    <ng-template pTemplate="start">
      <div (click)="backToMonitorPage()" class="flex flex-row gap-1 cursor-pointer text-surface-900 dark:text-surface-0">
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.57578 2.55965C6.84033 2.2951 6.84033 1.86619 6.57578 1.60164C6.31123 1.33709 5.88232 1.33709 5.61777 1.60164L0.198412 7.02099C-0.0661372 7.28554 -0.0661372 7.71446 0.198412 7.97901L5.61777 13.3984C5.88232 13.6629 6.31123 13.6629 6.57578 13.3984C6.84033 13.1338 6.84033 12.7049 6.57578 12.4403L2.31285 8.17742H13.3226C13.6967 8.17742 14 7.87413 14 7.5C14 7.12587 13.6967 6.82258 13.3226 6.82258H2.31285L6.57578 2.55965Z"
                fill="#334155"/>
        </svg>
        <span >go back</span>
      </div>
    </ng-template>
  </p-menubar>
  <div class="monitor-card m-2">
    <div class="header text-500-20px">
      <span class="text-500-20px">
        @if (monitorData.status == 'DOWN') {
          <i class="pi pi-chevron-circle-down lg:font-bold cursor-pointer border-round-xl lg:text-2xl bg-red-50 "></i>
        } @else {
          <i class="pi pi-chevron-circle-up lg:font-bold cursor-pointer border-round-xl lg:text-2xl bg-red-50"></i>
        }
        {{ httpUrl }}</span>
    </div>
    <div class="text-500-16px mt-2">
      HTTP/S monitor for
      <a [href]="httpUrl" target="_blank" rel="noopener noreferrer" class="text-green-50-500 underline">
        {{ httpUrl }}
      </a>
    </div>

    <div class="status-section">
      <div class="generic-card-monitor gap-4 p-5 text-500-14px">
        <div>
          <span>Current status: </span>
          <span [class]="monitorData.status === 'DOWN' ? 'status-down' : 'status-up'">
            {{ monitorData.status }}
          </span>
        </div>
        <div>
          <span class="text-500-14px">Currently {{ monitorData.status }} for {{ monitorData.currentStatusDuration | duration }}</span>
        </div>
      </div>
      <div class="generic-card-monitor">
        <div>
          <span class="text-500-14px">Last check: {{ monitorData.monitorStatusCheckedAt | date: 'MMM d, y HH:mm:ss'  }}</span>
        </div>
        <!-- <h3></h3> -->
        <div>
            <span class="text-500-16px">
              Checked every {{ monitorData?.scheduleInterval?.replace('_', ' ').toLowerCase() || 'not available' }}
            </span>
        </div>
      </div>
      <div class="generic-card-monitor">
        <div class="monitor-header">
          <span class="text-500-14px"> Last 24 hours</span>
          <h3>{{ monitorData?.monitor24HourStatsDto?.uptimePercentage ? monitorData?.monitor24HourStatsDto?.uptimePercentage + '%' : '0.00%' }}</h3>
        </div>
        <carvia-coloured-stats-bar [statsBarInput]="graphInput"></carvia-coloured-stats-bar>
        <p>{{ monitorData?.monitor24HourStatsDto?.incidentCount }} Incident</p>
      </div>
    </div>

    <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
      <p-tabPanel header="Incidents">
        <div class="statistics">
          @if (shimmerLoading) {
            <app-skeleton-table [loading]="shimmerLoading" [headers]="incidenceHeaders"></app-skeleton-table>
          }
          @if (incidenceData.length > 0) {
            <p-table
              [value]="incidenceData"
              [first]="incidencePage.number * incidencePage.size"
              stripedRows
              [paginator]="incidenceData.length >0"
              [rows]="incidencePage.size"
              [showCurrentPageReport]="incidenceData.length >0"
              [tableStyle]="{ 'min-width': '50rem' }"
              [lazy]="true"
              size="small"
              (onPage)="onIncidencePageChange($event)"
              [totalRecords]="incidencePage.totalElements"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[5,10,25,50]"
              class="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                  @for (incidenceHeader of incidenceHeaders; track incidenceHeaders){
                  <th>{{ incidenceHeader }}</th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr class="text-500-16px">
                  <td>
                    <p-tag
                      [value]="item.status"
                        [severity]="item.status === 'ONGOING' ? 'danger' : (item.status === 'RESOLVED' ? 'success' : 'info')">
                    </p-tag>
                  </td>
                  <td showDelay="100" pTooltip="{{item.rootCause}}">{{ item.rootCause.substring(0,100)}}...</td>
                  <td>{{ item.createdDate | date }}</td>
                  <td>{{ item.incidentDuration | duration }}</td>
                </tr>
              </ng-template>


            </p-table>
          } @else if (incidenceData.length == 0 && !loading) {
            <div style="text-align: center;">
              <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_814_160)">
                  <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_814_160">
                    <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg></div>
              <div class=" flex justify-content-center text-2xl my-2">
                No Data Found
              </div>
              <div class="info-2 flex justify-content-center text-xl my-2">
                There is no incidents. Everything seems to be working fine
              </div>

            </div>
          }
        </div>

      </p-tabPanel>
      <p-tabPanel header="Logs">
        @if (shimmerLoading) {
          <app-skeleton-table [loading]="shimmerLoading" [headers]="logsHeader"></app-skeleton-table>
        }
        @if (logsData.length > 0) {
          <p-table
            [value]="logsData"
            [first]="logsPage.number * logsPage.size"
            stripedRows
            [paginator]="true"
            [rows]="logsPage.size"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            [lazy]="true"
            (onPage)="onLogsPageChange($event)"
            [totalRecords]="logsPage.totalElements"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[5,10,25,50]"
            dataKey="id"
            size="small"
            [expandedRowKeys]="expandedRowKeys"
            (onRowExpand)="onRowExpand($event)"
            (onRowCollapse)="onRowCollapse($event)"
            class="p-datatable-sm">
            <ng-template pTemplate="header">
              <tr>
                @for (header of logsHeader; track header){
                  <th>{{ header }}</th>
                }
              </tr>

            </ng-template>
            <ng-template pTemplate="body" let-item let-expanded="expanded">
              <tr class="cursor-pointer " [pRowToggler]="item">
                <td class="text-500-16px">{{ item.createdDate | date: 'dd-MM-yyyy HH:mm' }}</td>
                <td class="text-500-16px">{{ item.executionTime | duration}}</td>
                <td class="text-500-16px">
                  {{
                    item.responseCode === 200
                      ? item.responseCode
                      : item.responseCode + ' (' + (item.errorStackTrace ? item.errorStackTrace.split(':')[0] : 'Unknown Error') + ')'
                  }}
                </td>
              </tr>
            </ng-template>
            <ng-template #expandedrow let-item>
              <tr>
                <td colspan="4">
                  @if(item.httpMonitorExecutionValidationResult === null) {
                          <p-card>
                            <ng-template #title>
                              <span style="color: orange;">No results found for this record!</span>
                            </ng-template>
                          </p-card>
                  } @else {
                  <div class="grid-container">
                    <div class="http-status-validation">
                      <h5>Http Status</h5>
                      <p-card>
                        <ng-template #title>
                          @if (item.responseCode === 200) {
                            <span style="color: green;">Success (200)</span>
                          } @else{
                            <span style="color: red;">Failed ({{item.responseCode}})</span>
                          }
                        </ng-template>
                        <div class="p-1">
                          Expected Code: 200
                        </div>
                      </p-card>
                      @if(item.httpMonitorExecutionValidationResult === null) {
                        <p-card>
                          <ng-template #title>
                            <span style="color: red;">Something went wrong before validation!</span>
                          </ng-template>
                        </p-card>
                      } @else {
                        <div>
                          <h5>Validations</h5>
                            @for (validationResult of item.httpMonitorExecutionValidationResult.executionValidationResults; track validationResult) {
                              <div class="mt-2 mb-1">
                                <p-card >
                                  <ng-template #title>
                                    @if(validationResult.success) {
                                      <span style="color: green;">Success</span>
                                      <i class="ml-2 pi pi-check-circle" style="color: green;"></i>
                                    } @else if (!validationResult.success || validationResult.success === undefined) {
                                      <span style="color: red;">Failed</span>
                                      <i class="ml-2 pi pi-times" style="color: red;"></i>
                                    }
                                  </ng-template>
                                  <div class="p-1">Validation:
                                    <code class="inline-code">
                                      {{ parseValidation(validationResult) }}
                                    </code>
                                  </div>
                                  <div class="p-1">Message: {{validationResult.message}}</div>
                                </p-card>
                              </div>
                            }
                        </div>
                      }
                    </div>
                    <div class="response-body">
                      <h5>Response Details</h5>
                      <pre class="json-pre">{{ logTheObj(item.responseDto) }}</pre>
                    </div>
                  </div>
                }
                </td>
              </tr>
            </ng-template>
          </p-table>
        } @else if (logsData.length == 0 && !loading) {
          <div style="text-align: center; margin-top: 4%">
            <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_814_160)">
                <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_814_160">
                  <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg></div>
            <div class=" flex justify-content-center text-2xl my-2">
              No Data Found
            </div>
            <div class="info-2 flex justify-content-center text-xl my-2">
              There is no logs yet. Everything seems to be working fine
            </div>

          </div>
        }
      </p-tabPanel>

      <p-tabPanel header="Alerts">
        <div class="statistics">
          @if (shimmerLoading) {
            <app-skeleton-table [loading]="shimmerLoading" [headers]="alertsHeader"></app-skeleton-table>
          }
          @if (alertsData.length > 0) {
            <p-table
              [value]="alertsData"
              [first]="alertsPage.number * alertsPage.size"
              stripedRows
              [paginator]="alertsData.length >0"
              [rows]="alertsPage.size"
              [showCurrentPageReport]="alertsData.length >0"
              [tableStyle]="{ 'min-width': '50rem' }"
              [lazy]="true"
              size="small"
              (onPage)="onAlertsPageChange($event)"
              [totalRecords]="alertsPage.totalElements"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[5,10,25,50]"
              class="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                @for (header of alertsHeader; track header){
                  <th>{{ header }}</th>
                }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr class="text-500-16px">
                  <td>
                    {{ item.createdDate | date: 'dd-MM-yyyy HH:mm' }}
                  </td>
                  <td>
            <span
              [ngClass]="{
                'ongoing-status': item.status === 'ONGOING',
                'resolved-status': item.status === 'RESOLVED',
              }"
            >
              {{ item.status }}
            </span>
                  </td>
                  <td>
                    <!--                  <p-button label="View Details" variant="text" [raised]="true" severity="secondary"  class="bg-none" (click)="showDialog(item)" />-->
                    <span class="view-details" (click)="showDialog(item)">View Details</span>
                  </td>
                </tr>
              </ng-template>


            </p-table>
          } @else if (alertsData.length == 0 && !loading) {
            <div style="text-align: center;">
              <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_814_160)">
                  <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_814_160">
                    <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg></div>
              <div class=" flex justify-content-center text-2xl my-2">
                No Data Found
              </div>
              <div class="info-2 flex justify-content-center text-xl my-2">
                There is no alerts. Everything seems to be working fine
              </div>

            </div>
          }

          <p-dialog
            [(visible)]="showModal"
            [modal]="true"
            [closable]="true"
            [style]="{ width: '30vw' }"
            (onHide)="closeModal()"
            [draggable]="false"
          >
            <ng-template pTemplate="header">
              <div class="custom-dialog-header">
                <span> Alert Detail</span>
              </div>
            </ng-template>
            <hr />
            <div class="dialog-content p-1">
              <div class="dialog-header flex justify-between">
              <span >
                Created Time: {{ selectedItem?.createdDate | date: 'dd-MM-yyyy HH:mm' || 'N/A' }}
              </span>
              </div>
            </div>
            <p-table [value]="selectedItem?.notificationAlerts || []" stripedRows>
              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-alert>
                <tr>
                  <td>
                    @if (alert.integration.type=='Email') {
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1 13.05V4.94998C17.1 4.20298 16.497 3.59998 15.75 3.59998H3.141C2.394 3.59998 1.791 4.20298 1.791 4.94998V13.05C1.791 13.797 2.394 14.4 3.141 14.4H15.75C16.497 14.4 17.1 13.797 17.1 13.05ZM15.921 4.85098C16.218 5.14798 16.056 5.45398 15.894 5.60698L12.24 8.95498L15.75 12.609C15.858 12.735 15.93 12.933 15.804 13.068C15.687 13.212 15.417 13.203 15.3 13.113L11.367 9.75598L9.441 11.511L7.524 9.75598L3.591 13.113C3.474 13.203 3.204 13.212 3.087 13.068C2.961 12.933 3.033 12.735 3.141 12.609L6.651 8.95498L2.997 5.60698C2.835 5.45398 2.673 5.14798 2.97 4.85098C3.267 4.55398 3.573 4.69798 3.825 4.91398L9.441 9.44998L15.066 4.91398C15.318 4.69798 15.624 4.55398 15.921 4.85098Z" fill="black"/>
                      </svg>
                      {{alert.integration.email}}
                    }
                    @else {
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.36156 10.1107C3.36156 11.0358 2.60586 11.7915 1.68078 11.7915C0.755701 11.7915 0 11.0358 0 10.1107C0 9.18563 0.755701 8.42993 1.68078 8.42993H3.36156V10.1107Z" fill="#E01E5A"/>
                        <path d="M4.20845 10.1107C4.20845 9.18563 4.96415 8.42993 5.88923 8.42993C6.81431 8.42993 7.57001 9.18563 7.57001 10.1107V14.3192C7.57001 15.2443 6.81431 16 5.88923 16C4.96415 16 4.20845 15.2443 4.20845 14.3192V10.1107Z" fill="#E01E5A"/>
                        <path d="M5.88923 3.36156C4.96415 3.36156 4.20845 2.60586 4.20845 1.68078C4.20845 0.755701 4.96415 0 5.88923 0C6.81431 0 7.57001 0.755701 7.57001 1.68078V3.36156H5.88923Z" fill="#36C5F0"/>
                        <path d="M5.88925 4.2085C6.81433 4.2085 7.57003 4.9642 7.57003 5.88928C7.57003 6.81436 6.81433 7.57006 5.88925 7.57006H1.68078C0.755701 7.57006 0 6.81436 0 5.88928C0 4.9642 0.755701 4.2085 1.68078 4.2085H5.88925Z" fill="#36C5F0"/>
                        <path d="M12.6384 5.88928C12.6384 4.9642 13.3941 4.2085 14.3192 4.2085C15.2443 4.2085 16 4.9642 16 5.88928C16 6.81436 15.2443 7.57006 14.3192 7.57006H12.6384V5.88928Z" fill="#2EB67D"/>
                        <path d="M11.7915 5.88925C11.7915 6.81433 11.0358 7.57003 10.1107 7.57003C9.18566 7.57003 8.42996 6.81433 8.42996 5.88925V1.68078C8.42996 0.755701 9.18566 0 10.1107 0C11.0358 0 11.7915 0.755701 11.7915 1.68078V5.88925Z" fill="#2EB67D"/>
                        <path d="M10.1108 12.6384C11.0358 12.6384 11.7915 13.3941 11.7915 14.3192C11.7915 15.2443 11.0358 16 10.1108 16C9.18568 16 8.42998 15.2443 8.42998 14.3192V12.6384H10.1108Z" fill="#ECB22E"/>
                        <path d="M10.1107 11.7915C9.18566 11.7915 8.42996 11.0358 8.42996 10.1107C8.42996 9.18563 9.18566 8.42993 10.1107 8.42993H14.3192C15.2443 8.42993 16 9.18563 16 10.1107C16 11.0358 15.2443 11.7915 14.3192 11.7915H10.1107Z" fill="#ECB22E"/>
                      </svg>
                      {{alert.integration.channelName}}  |  Workspace - {{alert.integration.teamName}}
                    }
                  </td>
                  <td>
                  <span
                    [ngClass]="{
                      'ongoing-status': alert.status === 'FAILED',
                      'resolved-status': alert.status === 'SUCCESS',
                     }"
                  >
                    {{ alert?.status }}
                  </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-dialog>

        </div>

      </p-tabPanel>

    </p-tabView>
  </div>
</div>
