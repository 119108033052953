<div class="wrapper col-2">
  <aside class="left-sidebar" id="side-bar">
    <div class="sidebar_admin flex-column min-vh-100 position-relative overflow-hidden h-100 d-flex sidebar-with-footer">
      <div class="position-relative flex-column flex-wrap align-content-start justify-content-start align-items-start h-100">
        <div class="simplebar-content">
          <p-drawer [(visible)]="sidebarVisible" [modal]="false" [closeOnEscape]="false">
            <ng-template pTemplate="headless">
              <div class="flex align-items-center justify-content-between px-4 mt-3 flex-shrink-0">
                <div class="inline-flex align-items-center">
                  <svg height="40" width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                    <ellipse rx="54.514671" ry="58.577877" transform="matrix(1.32298 0 0 1.265902 150 150)" fill="none" stroke="#3730A3" stroke-width="25"/>
                    <ellipse rx="30" ry="30" transform="matrix(.778782 0 0 0.826184 120.626156 125.21448)" fill="#3730A3"/>
                  </svg>
                  <span class="text-500-20px" id="logo-text">Web Watch</span>
                </div>
              </div>
              <div class="overflow-y-auto">
                <ul class="list-none p-3 m-0">

                  <li class="nav-item active:bg-indigo-100" [routerLinkActive]="['active']" >
                    <a [routerLink]="['dashboard']"
                       class=" active:bg-indigo-100 flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-home mr-4"></i>
                        <span class="nav-text ms-2">Dashboard</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a [routerLink]="['projects']"
                       class=" flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-briefcase mr-4"></i>
                        <span class="nav-text ms-2">Projects</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a [routerLink]="['incidents']"
                       class=" flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-exclamation-triangle mr-4"></i>
                        <span class="nav-text ms-2">Incidents</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a [routerLink]="['monitors']"
                       class=" flex cursor-pointer p-3 outline-none highlight transition-duration-150 ">
                      <div>
                        <i class="pi pi-bullseye mr-4"></i>
                        <span class="nav-text ms-2">Monitors</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a
                      [routerLink]="['integrations']"
                       class=" flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-sitemap mr-4"></i>
                        <span class="nav-text ms-2">Integrations</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a [routerLink]="['users']"
                       class=" flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-user mr-4"></i>
                        <span class="nav-text ms-2">Users</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a [routerLink]="['status']"
                       class=" flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-user mr-4"></i>
                        <span class="nav-text ms-2">Status Pages</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a [routerLink]="['alerts']"
                       class=" flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-exclamation-triangle mr-4"></i>
                        <span class="nav-text ms-2">Alert Groups</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item" [routerLinkActive]="['active']">
                    <a [routerLink]="['actions']"
                       class=" flex align-items-center cursor-pointer p-3 border-round">
                      <div>
                        <i class="pi pi-code mr-4"></i>
                        <span class="nav-text ms-2">Actions</span>
                      </div>
                    </a>
                  </li>

                  <li class="nav-item w-full" >
                      <div (click)="toggleAccordion()" class=" flex align-items-center cursor-pointer p-3 border-round">
                        <div>
                          <i class="pi pi-list mr-4"></i>
                          <span class="nav-text ms-2">Project Listing</span>
                        </div>
                        <div class="ml-6">
                          <i class="pi" [ngClass]="{'pi-chevron-down': !isExpanded, 'pi-chevron-up': isExpanded}"></i>
                        </div>
                      </div>

                    <ul #accordionContent class="list-none pl-4 accordion-content" [ngClass]="{'expanded': isExpanded}">
                      @for(project of menuItems; track project.id){
                        <li (click)="navigateToProject(project.id)" class="cursor-pointer">
                          <a [routerLink]="project.routerLink" class=" flex align-items-center cursor-pointer p-3 border-round">
                            <i class="pi pi-folder mr-4"></i>
                            <span class="nav-text ms-2">{{ project.label }}</span>
                          </a>
                        </li>
                      }
                    </ul>
                  </li>
                </ul>
              </div>
            </ng-template>
          </p-drawer>
        </div>
      </div>
    </div>
  </aside>
</div>
