<p-toast/>
@if(loading){
  <p-progress-spinner/>
}
<div class="card">
  @switch(selectedIntegration){
    @case('Email'){
      <div>
        <app-create-email-integration [visible]="true" (creationCancelledEvent)="emailCreationCancelled($event)" (newEmailIntegrationEvent)="newEmailIntegrationCreated($event)"/>
      </div>
    }
    @case ('Slack'){
      <div>
        <app-create-slack-integration/>
      </div>
    }
  }
  <p-menubar class="menu " styleClass="border-none pl-0 pr-0">
    <ng-template pTemplate="start">
      <span class="text-2xl">Integrations</span>
    </ng-template>
  </p-menubar>
  <p-confirmDialog/>
  <p-menubar class="menu" styleClass="border-none pl-0 pr-0">
    <ng-template pTemplate="start" >
      <div class="flex align-items-center gap-3">
<!--        TODO: enable only when working-->
        <input [disabled]="true" type="text" class="w-16rem" placeholder="Search" pInputText [(ngModel)]="searchInputText" />
        <span class="text-xl border-left-1 pl-4 ml-4 p-1">Quick Filter</span>

        <div class="flex justify-content-center ">
<!--          TODO: enable only when working-->
                   <p-select
                     [disabled]="true"
                  [options]="types"
                  [(ngModel)]="selectedType"
                  optionLabel="name"
                  [showClear]="true"
                  placeholder="Type" />
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="end">
      <p-select [options]="integrationOptions" [(ngModel)]="selectedIntegration" placeholder="Select an Integration" class="w-full md:w-56"/>
    </ng-template>
  </p-menubar>
  <div class="mt-2">
  <div class="overflow-x-auto w-full cards-container">
    @if (integrations.length>0){
    <p-table
      [value]="integrations"
      [loading]="loading"
      [rows]="10"
      stripedRows
      size="small"
    >
      <ng-template #body let-integration>
        <tr class="text-500-16px">
            <td>
              @if (integration.type=='Email') {
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.1 13.05V4.94998C17.1 4.20298 16.497 3.59998 15.75 3.59998H3.141C2.394 3.59998 1.791 4.20298 1.791 4.94998V13.05C1.791 13.797 2.394 14.4 3.141 14.4H15.75C16.497 14.4 17.1 13.797 17.1 13.05ZM15.921 4.85098C16.218 5.14798 16.056 5.45398 15.894 5.60698L12.24 8.95498L15.75 12.609C15.858 12.735 15.93 12.933 15.804 13.068C15.687 13.212 15.417 13.203 15.3 13.113L11.367 9.75598L9.441 11.511L7.524 9.75598L3.591 13.113C3.474 13.203 3.204 13.212 3.087 13.068C2.961 12.933 3.033 12.735 3.141 12.609L6.651 8.95498L2.997 5.60698C2.835 5.45398 2.673 5.14798 2.97 4.85098C3.267 4.55398 3.573 4.69798 3.825 4.91398L9.441 9.44998L15.066 4.91398C15.318 4.69798 15.624 4.55398 15.921 4.85098Z" fill="black"/>
                </svg>
              }
              @else {
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.36156 10.1107C3.36156 11.0358 2.60586 11.7915 1.68078 11.7915C0.755701 11.7915 0 11.0358 0 10.1107C0 9.18563 0.755701 8.42993 1.68078 8.42993H3.36156V10.1107Z" fill="#E01E5A"/>
                  <path d="M4.20845 10.1107C4.20845 9.18563 4.96415 8.42993 5.88923 8.42993C6.81431 8.42993 7.57001 9.18563 7.57001 10.1107V14.3192C7.57001 15.2443 6.81431 16 5.88923 16C4.96415 16 4.20845 15.2443 4.20845 14.3192V10.1107Z" fill="#E01E5A"/>
                  <path d="M5.88923 3.36156C4.96415 3.36156 4.20845 2.60586 4.20845 1.68078C4.20845 0.755701 4.96415 0 5.88923 0C6.81431 0 7.57001 0.755701 7.57001 1.68078V3.36156H5.88923Z" fill="#36C5F0"/>
                  <path d="M5.88925 4.2085C6.81433 4.2085 7.57003 4.9642 7.57003 5.88928C7.57003 6.81436 6.81433 7.57006 5.88925 7.57006H1.68078C0.755701 7.57006 0 6.81436 0 5.88928C0 4.9642 0.755701 4.2085 1.68078 4.2085H5.88925Z" fill="#36C5F0"/>
                  <path d="M12.6384 5.88928C12.6384 4.9642 13.3941 4.2085 14.3192 4.2085C15.2443 4.2085 16 4.9642 16 5.88928C16 6.81436 15.2443 7.57006 14.3192 7.57006H12.6384V5.88928Z" fill="#2EB67D"/>
                  <path d="M11.7915 5.88925C11.7915 6.81433 11.0358 7.57003 10.1107 7.57003C9.18566 7.57003 8.42996 6.81433 8.42996 5.88925V1.68078C8.42996 0.755701 9.18566 0 10.1107 0C11.0358 0 11.7915 0.755701 11.7915 1.68078V5.88925Z" fill="#2EB67D"/>
                  <path d="M10.1108 12.6384C11.0358 12.6384 11.7915 13.3941 11.7915 14.3192C11.7915 15.2443 11.0358 16 10.1108 16C9.18568 16 8.42998 15.2443 8.42998 14.3192V12.6384H10.1108Z" fill="#ECB22E"/>
                  <path d="M10.1107 11.7915C9.18566 11.7915 8.42996 11.0358 8.42996 10.1107C8.42996 9.18563 9.18566 8.42993 10.1107 8.42993H14.3192C15.2443 8.42993 16 9.18563 16 10.1107C16 11.0358 15.2443 11.7915 14.3192 11.7915H10.1107Z" fill="#ECB22E"/>
                </svg>
              }
            </td>
          <td>
            @if (integration.type=='Email') {
              {{integration.email}}
            }
            @else {
              {{integration.channelName}}  |  Workspace - {{integration.teamName}}
            }
          </td>
          <td>{{ integration.createdDate | date: 'dd-MMM-yyyy HH:mm:ss' }}</td>
          <td>
            <div class="flex justify-center relative">
              <button pButton icon="pi pi-ellipsis-h" outlined="true" class="border-none" (click)="showMenu($event, menu, integration)"></button>
              <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body" class="custom-menu"></p-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    }@else if (integrations.length==0 && !loading){
      <div style="text-align: center; margin-top: 4%">
        <div><svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_814_160)">
            <path d="M23.8333 39.6667C32.854 39.6667 40.1667 32.354 40.1667 23.3333C40.1667 14.3127 32.854 7 23.8333 7C14.8127 7 7.5 14.3127 7.5 23.3333C7.5 32.354 14.8127 39.6667 23.8333 39.6667Z" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49.5 49L35.5 35" stroke="#64748B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_814_160">
              <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg></div>
        <div class=" flex justify-content-center text-2xl my-2">
          No Data Found
        </div>
        <div class="info-2 flex justify-content-center text-xl my-2">
          There is no integrations yet.Please add some integrations
        </div>

      </div>
    }
  </div>
  </div>

</div>
