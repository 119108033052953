
import {Component, inject, Input, OnInit, ViewChild} from '@angular/core';
import {NgClass, NgStyle} from '@angular/common';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {Button} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {FormsModule} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccordionModule} from 'primeng/accordion';
import {ChipModule} from 'primeng/chip';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import { CreateHttpMonitorComponent } from "../../components/create-monitor/create-http-monitor/create-http-monitor.component";
import { Select } from 'primeng/select';
import {
  CreateSslMonitorComponent
} from '../../components/create-monitor/create-ssl-monitor/create-ssl-monitor.component';
import {MonitorTypeConverterPipe} from '../../pipes/type-converter-pipes/monitor-type-to-monitor.pipe';
import {Type} from '../../models/enums';
import { Monitor } from '../../models/monitors/monitor-responses';
import { MonitorType } from '../../models/monitors/monitor-requests';
import { MultiSelect } from 'primeng/multiselect';
import { AlertGroupService } from '../../services/alert-group/alert-group.service';
import { AlertGroupResponse } from '../../models/alert-group/alert-group-response';
import {ProjectService} from '../../services/projectService';
import {ProjectRequest} from '../../models/project/project-request';
import {MonitorService} from '../../services/monitor/monitor.service';
import { RippleModule } from 'primeng/ripple';

// noinspection TypeScriptValidateTypes
@Component({
  selector: 'app-create-monitor',
  standalone: true,
  imports: [
    NgClass,
    MenuModule,
    MenubarModule,
    Button,
    CardModule,
    FormsModule,
    ChipModule,
    AccordionModule,
    ChipModule,
    ConfirmDialogModule,
    ToastModule,
    CreateHttpMonitorComponent,
    Select,
    CreateSslMonitorComponent,
    MultiSelect,
    RippleModule,
    NgStyle
  ],
  templateUrl: './create-monitor.component.html',
  styleUrl: './create-monitor.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class CreateMonitorComponent implements OnInit{
  sideBarVisible: boolean = false;
  private subscription!: Subscription;
  selectedMonitorType: MonitorType = {name: 'HTTPS', code: 'https'};
  monitorTypeOptions: MonitorType[] =  [
    {name: 'HTTPS', code: 'https'},
    {name: 'SSL', code: 'ssl'},
  ];

  projectId: string = '';

  editMode: boolean = false;
  selectedMonitorForEditId!: number;
  @ViewChild(CreateSslMonitorComponent) sslMonitor!: CreateSslMonitorComponent;
  @ViewChild(CreateHttpMonitorComponent) httpMonitor!: CreateHttpMonitorComponent;

  private router: Router = inject(Router);
  private sidebarState: SidebarStateService = inject(SidebarStateService);
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private monitorTypeConverter: MonitorTypeConverterPipe = inject(MonitorTypeConverterPipe);
  private alertGroupService: AlertGroupService = inject(AlertGroupService);
  private projectService: ProjectService = inject(ProjectService);
  private monitorService: MonitorService = inject(MonitorService);

  selectedAlertGroupIds: number[] = []
  alertGroups: AlertGroupResponse[] = []
  projects: ProjectRequest[] = []
  selectedProject?: ProjectRequest;

  constructor() {
   const navigation = this.router.getCurrentNavigation();
    const monitor:Monitor = navigation?.extras.state?.['monitor']
    if (monitor) {
      this.editMode = true;
      this.selectedMonitorType = this.monitorTypeConverter.transform(monitor.monitorType) as MonitorType;
      this.selectedMonitorForEditId = monitor.id;
    }
  }

  ngOnInit(): void {

    this.subscription = this.sidebarState.sidebarVisible$.subscribe(visible => {
      this.sideBarVisible = visible;
    });

    this.alertGroupService.getAll().subscribe({
      next: data => {
        this.alertGroups = data;
      },
      error: error => {
        console.log(error);
      },
      complete: () => {
        console.log('alertGroups', this.alertGroups);
      }
    });

    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'] || null;
      console.log('Extracted projectId from query params:', this.projectId);
      this.getProjects();
    });

    this.monitorService.selectedProject$.subscribe(project => {
      this.selectedProject = project;
      console.log('Selected Project from Service:', this.selectedProject);
    });
  }


  getProjects() {
    this.projectService.getAll().subscribe({
      next: data => {
        this.projects = data;

        this.setSelectedProject();
      },
      error: error => {
        console.log(error);
      },
      complete: () => {
        console.log('Projects:', this.projects);
      }
    });
  }



  setSelectedProject() {
    if (!this.projectId) {
      this.selectedProject = undefined;
      console.log('No projectId in URL, keeping dropdown unselected.');
      return;
    }

    // ✅ Find the project only if `projectId` exists
    const foundProject = this.projects.find(p => p.id.toString() === this.projectId);

    if (foundProject) {
      this.selectedProject = foundProject;

      this.monitorService.setSelectedProject(foundProject);
    } else {
      console.log('No matching project found for projectId:', this.projectId);
    }
  }



  toMonitor(selectedMonitorType: MonitorType){
    switch (selectedMonitorType.name){
      case 'HTTPS':
        return this.httpMonitor.httpMonitorPayload
        break
      case 'SSL':
        return this.sslMonitor.sslPayloadForm
        break
    }
    return null
  }

  navigateAway(){
    if(this.toMonitor(this.selectedMonitorType)?.touched){
      this.confirmationService.confirm({
        message: '<div class="flex flex-column justify-content-center align-items-center"><span>Are you sure?</span><span>Your changes to the form will be lost!</span></div>',
        icon: 'pi pi-info-circle',
        header: 'Confirmation',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.backToMonitorPage();
        },
        reject: () => {},
        key: 'positionDialog'
      })
    } else {
      this.backToMonitorPage()
    }
  }

  backToMonitorPage() {
    if(!this.selectedProject){
      this.router.navigate(['/monitors']);
    } else {
      this.router.navigate(['/list/project',this.projectId,'monitors']);
    }
  }

  loadAlertGroupIdsInMultiSelect($event: number[]){
      this.selectedAlertGroupIds = $event
  }

  submitForm() {
    switch (this.selectedMonitorType.name) {
      case 'HTTPS':
        this.httpMonitor.submitForm(this.selectedAlertGroupIds,this.selectedProject);
        break;
      case 'SSL':
        this.sslMonitor.submitForm(this.selectedAlertGroupIds,this.selectedProject);
        break;
    }
  }
}
