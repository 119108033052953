import {Component, ElementRef, inject} from '@angular/core';
import { AppMenu } from "./app.menu";
import {MenuItem} from 'primeng/api';
import {ProjectService} from '../services/projectService';
// import { AppMenu } from './app.menu';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [AppMenu],
    template: ` <div class="layout-sidebar">
        <app-menu [model]="model"></app-menu>
    </div>`
})
export class AppSidebar {
    constructor(public el: ElementRef) {}
    model: MenuItem[] = []
    projects: MenuItem[] = []
    private _projectService: ProjectService = inject(ProjectService)
    ngOnInit() {
      this._projectService.getAll().subscribe({
        next: items => {
          items.forEach(item => {
            this.projects.push({label: item.name, routerLink: ['list/project', item.id], icon: 'pi pi-fw pi-folder'});
          })
        }
      })
      this.model = [
        {
          label: 'Home',
          items: [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['dashboard'] },
            { label: 'Integrations', icon: 'pi pi-fw pi-sitemap', routerLink: ['integrations'] },
            { label: 'Monitors', icon: 'pi pi-fw pi-bullseye', routerLink: ['monitors'] },
            { label: 'Incidents', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['incidents'] },
            { label: 'Projects', icon: 'pi pi-fw pi-briefcase', routerLink: ['projects'] },
            { label: 'Users', icon: 'pi pi-fw pi-user', routerLink: ['users'] },
            { label: 'Status Page', icon: 'pi pi-fw pi-align-justify', routerLink: ['status'] },
            { label: 'Alerts', icon: 'pi pi-fw pi-bell', routerLink: ['alerts'] },
            { label: 'Projects List', icon: 'pi pi-fw pi-list',
              items: this.projects, preserveFragment: true, routerLinkActiveOptions: {"exact": true} },
          ],
        }
      ];
    }
}
