<div class="card overflow-x-auto" >
  @switch(selectedIntegration){
    @case('Email'){
      <app-create-email-integration [visible]="true" (newEmailIntegrationEvent)="newEmailIntegrationCreated($event)"/>
    }
    @case ('Slack'){
      <app-create-slack-integration/>
    }
  }
  <div class="flex flex-column" [formGroup]="alertGroupForm">
    <p-menubar class="menu" styleClass="border-none pl-0 pr-0">
      <ng-template pTemplate="start">
        <p-toast />
        <p-confirmDialog
          key="positionDialog"
          rejectButtonStyleClass="p-button-outlined" />
        <div (click)="navigateAway()" class="flex flex-row gap-1 cursor-pointer" >
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57578 2.55965C6.84033 2.2951 6.84033 1.86619 6.57578 1.60164C6.31123 1.33709 5.88232 1.33709 5.61777 1.60164L0.198412 7.02099C-0.0661372 7.28554 -0.0661372 7.71446 0.198412 7.97901L5.61777 13.3984C5.88232 13.6629 6.31123 13.6629 6.57578 13.3984C6.84033 13.1338 6.84033 12.7049 6.57578 12.4403L2.31285 8.17742H13.3226C13.6967 8.17742 14 7.87413 14 7.5C14 7.12587 13.6967 6.82258 13.3226 6.82258H2.31285L6.57578 2.55965Z" fill="#334155"/>
          </svg>
          <span class="text-500-14px">go back</span>
        </div>
      </ng-template>
    </p-menubar>
    <p-menubar class="menu" styleClass="border-none pl-0 pr-0">
      <ng-template pTemplate="start">
        @if (alertId){
          <span class="text-2xl">Edit Alert Group</span>
        } @else {
          <span class="text-2xl">Create Alert Group</span>
        }
      </ng-template>
      <ng-template pTemplate="end">
        <p-button label="Save Alert Group" (onClick)="submitForm()"></p-button>
      </ng-template>
    </p-menubar>
    <!-- <p-card class="align-self-center"> -->
    <div class="mt-2">
      <div >
        <div class="flex flex-column gap-3">
          <span class="text-xl">Alert Group Name*</span>
          <input type="text" class="border-round-xl" aria-describedby="name-help" formControlName="name" placeholder="Name" pInputText/>
          <small id="name-help">Give a friendly name to alert group</small>
          <span class="text-xl">Description</span>
          <input type="text" class="border-round-xl" placeholder="Description" formControlName="description" pInputText/>
        </div>
      </div>
  
      <div class="integrations">
        <div>
          <div class="flex flex-column gap-3 mt-4">
            <span class="text-2xl font-medium mb-2">Integrations</span>
  
            <span class="text-xl">Email</span>
            <p-multiSelect
              appendTo="body"
              [options]="emailIntegrations"
              optionLabel="email"
              [(ngModel)]="selectedEmailIntegration"
              [ngModelOptions]="{standalone: true}"
              display="chip">
              <ng-template #dropdownicon>
                <i class="pi pi-at"></i>
              </ng-template>
              <ng-template #filterPlaceHolder>
                Search Emails
              </ng-template>
              <ng-template #footer>
                <div class="p-3 flex justify-between">
  
                  <p-button
                    label="Add New"
                    severity="secondary"
                    text
                    size="small"
                    icon="pi pi-plus"
                    (click)="setIntegrationType('Email')">
                  </p-button>
                  <p-button label="Remove All" severity="danger" text size="small" icon="pi pi-times" (click)="removeEmailIntegrations()"></p-button>
                </div>
              </ng-template>
            </p-multiSelect>
  
            <span class="text-2xl">Slack</span>
            <p-multiSelect
              appendTo="body"
              [options]="slackIntegrations"
              optionLabel="channelName"
              [(ngModel)]="selectedSlackIntegration"
              [ngModelOptions]="{standalone: true}"
              display="chip">
              <ng-template #dropdownicon1>
                <i class="pi pi-slack"></i>
              </ng-template>
              <ng-template #filterPlaceHolder>
                Search Teams
              </ng-template>
              <ng-template #footer>
                <div class="p-3 flex justify-between">
                  <p-button label="Add New" severity="secondary" text size="small" icon="pi pi-plus"   (click)="setIntegrationType('Slack')"></p-button>
                  <p-button label="Remove All" severity="danger" text size="small" icon="pi pi-times" (click)="removeSlackIntegrations()"></p-button>
                </div>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>


