import {Component, inject} from '@angular/core';
import {CrashlyticsService} from '../../services/crashlytics/crashlytics.service';
import {ActivatedRoute} from '@angular/router';
import { ChartModule } from 'primeng/chart';

@Component({
  selector: 'app-project-crashlytics',
  standalone: true,
  imports: [ChartModule],
  templateUrl: './project-crashlytics.component.html',
  styleUrl: './project-crashlytics.component.scss'
})
export class ProjectCrashlyticsComponent {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private crashlyticsService: CrashlyticsService = inject(CrashlyticsService);
  crashlyticsData: any;
  projectId: number = 0;
  basicData: any;
  basicOptions: any;

  ngOnInit() {

    this.route.parent?.paramMap.subscribe(params => {
      const id = params.get('projectId');
      this.projectId = id ? Number(id) : 0;

      if (this.projectId>0) {
        this.fetchCrashlytics();
      }
    });

    this.basicOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 5
          }
        }
      }
    };
  }

  fetchCrashlytics(): void {
    this.crashlyticsService.getCrashlytics(this.projectId).subscribe({
      next: (data) => {
        data.lastOneDayErrorCount = 5;
        data.lastSevenDaysErrorCount = 15;
        data.lastOneMonthErrorCount = 45;
        data.totalErrorCount = 55;
        this.crashlyticsData = data;

        this.basicData = {
          labels: ['1 Day', '7 Days', '1 Month', 'Total'],
          datasets: [
            {
              label: 'Error Counts',
              data: [
                data.lastOneDayErrorCount,
                data.lastSevenDaysErrorCount,
                data.lastOneMonthErrorCount,
                data.totalErrorCount
              ],
              backgroundColor: [
                'rgba(75, 192, 192, 0.6)',
                'rgba(54, 162, 235, 0.6)',
                'rgba(255, 206, 86, 0.6)',
                'rgba(255, 99, 132, 0.6)',
              ],
              borderColor: [
                'rgba(75, 192, 192, 1)',

                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
              ],
              borderWidth: 2
            }
          ]
        };
      },
      error: (error) => {
        console.error('Error fetching crashlytics data:', error);
      }
    });
  }

  protected readonly JSON = JSON;
}
